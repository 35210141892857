<template>
  <b-sidebar
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New User Group
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="UserGroupAddForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="userGroup.code"
            name="code"
            label="User Group Code"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="userGroup.name"
            name="Name"
            label="Name"
            :rules="{ required: true }"
            trim />
          <SelectField
            v-model="userGroup.workingDay"
            :options="weekday"
            :rules="{ required: true }"
            :close-on-select="false"
            :deselect-from-dropdown="true"
            name="WorkingDay"
            form-label="Working Day"
            label-option="label"
            return-value="value"
            multiple />
          <p class="font-700">
            Working Time
          </p>
          <b-row>
            <b-col>
              <TimePicker
                v-model="userGroup.startTime"
                name="startTime"
                label="Start"
                :rules="{ required: true }" />
            </b-col>
            <b-col>
              <TimePicker
                v-model="userGroup.endTime"
                name="endTime"
                label="End"
                :rules="{ required: true }" />
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit">
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TimePicker from '@/components/Form/TimePicker.vue'

export default {
  components: {
    TextFieldInput,
    SelectField,
    TimePicker },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      initialUserGroup: {
        code: '',
        name: '',
        workingDay: [],
        startTime: null,
        endTime: null
      },
      userGroup: {
        code: '',
        name: '',
        workingDay: [],
        startTime: null,
        endTime: null
      },
      weekday: [
        { label: 'Sunday', value: 0 },
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 }
      ]
    }
  },
  computed: {
    sortedWorkingDay () {
      const sorted = [...this.userGroup.workingDay]
      sorted.sort()
      return sorted
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      this.$emit('add-user-group', this.userGroup)
    },
    resetForm () {
      this.userGroup = { ...this.initialUserGroup }
      this.$refs.UserGroupAddForm.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  min-width: 134px;
}
</style>

<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          User Group Detail
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>

      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="UserGroupDetailForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="userGroup.code"
            name="userGroupCode"
            label="Code"
            trim
            :rules="{ required: true }"
          />
          <TextFieldInput
            v-model="userGroup.name"
            name="Name"
            label="Name"
            :rules="{ required: true }"
            trim />
          <SelectField
            v-model="userGroup.workingDay"
            :options="weekday"
            :rules="{ required: true }"
            :close-on-select="false"
            :deselect-from-dropdown="true"
            name="WorkingDay"
            form-label="Working Day"
            label-option="label"
            return-value="value"
            multiple />
          <p class="font-700">
            Working Time
          </p>
          <b-row>
            <b-col>
              <TimePicker
                v-model="userGroup.startTime"
                name="startTime"
                label="Start"
                :rules="{ required: true }" />
            </b-col>
            <b-col>
              <TimePicker
                v-model="userGroup.endTime"
                name="endTime"
                label="End"
                :rules="{ required: true }" />
            </b-col>
          </b-row>
          <StatusRadioButton :status.sync="userGroup.status" />
          <b-row>
            <b-col>
              Created At : {{ userGroup.createdAt| formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ userGroup.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ userGroup.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ userGroup.updatedBy.username }}
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              @click="deleteItem()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TimePicker from '@/components/Form/TimePicker.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'

export default {
  components: {
    TextFieldInput,
    SelectField,
    TimePicker,
    StatusRadioButton
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialUserGroup: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      weekday: [
        { label: 'Sunday', value: 0 },
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 }
      ],
      date: ''
    }
  },
  computed: {
    sortedWorkingDay () {
      const sorted = [...this.userGroup.workingDay]
      sorted.sort()
      return sorted
    },
    userGroup: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.userGroup) === JSON.stringify(this.initialUserGroup)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        code: this.userGroup.code,
        id: this.userGroup.id,
        name: this.userGroup.name,
        start_time: this.userGroup.startTime,
        end_time: this.userGroup.endTime,
        working_day: this.userGroup.workingDay,
        status: this.userGroup.status
      }
      this.$emit('edit-user-group', payload)
    },
    resetForm () {
      this.userGroup = { ...this.initialUserGroup }
      this.$refs.UserGroupDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.userGroup.id,
        Name: this.userGroup.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  min-width: 134px;
}
</style>
